<template lang="html">
  <div class="my-4 px-10 py-1">
    <ol class="flex space-x-4 mb-3">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>&gt;</li>
      <li class="font-bold">
        <router-link to="your-green-options"> Your Green Options </router-link>
      </li>
    </ol>

    <div class="grid grid-cols-3 2xl:gap-5 gap-3">
      <router-link to="/renewable-options/buy-recs" :class="styles.card">
        <p class="text-center">Buy RECs</p>
      </router-link>
      <router-link
        to="/scope-2-renewables-performance/your-green-options/contact-us"
        :class="styles.card"
      >
        <p class="text-center">Contact Us</p>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="css" module="styles">
.card {
  @apply p-5 rounded-xl font-semibold text-xl h-40 flex items-center justify-center;
  color: #17a46d;
  border: solid 1px #5e5e5e;
  font-size: 30px;
}
</style>
